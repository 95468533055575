import {createStore, applyMiddleware} from "redux";
import {apiMiddleware} from "redux-api-middleware";
// import {syncHistoryWithStore} from "react-router-redux";
// import {browserHistory} from "react-router";

import rootReducer from "../reducers/root";

const enhancers = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const createStoreWithMiddleware = applyMiddleware(apiMiddleware)(createStore);
const store = createStoreWithMiddleware(rootReducer, {}, enhancers);
// export const history = syncHistoryWithStore(browserHistory, store);

export default store;
