import * as types from "../constants/actionTypes";

function modal(state = {}, action) {
  switch (action.type) {
    case types.CREATE_MODAL:
      return {
        header: action.header,
        content: action.content,
        active: action.active,
        objId: action.objId
      };
    case types.DELETE_MODAL:
      return {};
    case types.SHOW_MODAL:
      return Object.assign({}, state, {active: true});
    case types.HIDE_MODAL:
      return Object.assign({}, state, {active: false});
    default:
      return state;
  }
}

export default modal;
