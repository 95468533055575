import React from "react";
import PropTypes from 'prop-types';


export default class Tag extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    nb: PropTypes.number
  }
  render() {
    const {data, nb} = this.props;
    const labelStyle = {
      backgroundColor: data.color,
      color: data.text_color
    };

    return (
      <div className="ui label" style={labelStyle}>
        {nb ? <span>{nb} {data.name}</span> : <span>{data.name}</span>}
      </div>
    );
  }
}
