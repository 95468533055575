import React from "react";
import {NavLink} from "react-router-dom";
import PropTypes from 'prop-types';

import Tag from "./tags";

export default class Participants extends React.Component {
  static propTypes = {
    pdata: PropTypes.object.isRequired
  }
  render() {
    const pdata = this.props.pdata;
    const smallColumn = {width: "5%"};
    const mediumColumn = {width: "20%"};
    const largeColumn = {width: "70%"};
    return (
      <div>
        <h2>Équipes</h2>
        <table className="ui striped table">
          <thead>
            <tr>
              <th style={smallColumn}>#</th>
              <th style={mediumColumn}>Équipe</th>
              <th style={smallColumn}>Points</th>
              <th style={largeColumn}>Tags</th>
            </tr>
          </thead>
          <tbody>
            {pdata.hasOwnProperty("result") &&
              (pdata.result.length > 0 ?
                pdata.result.map((pid, i) =>
                  <ParticipantTableRow key={i} i={i} pid={pid} data={pdata}/>)
                :
                  <tr><td colSpan="4">Aucune équipe</td></tr>)}
          </tbody>
        </table>
      </div>
    );
  }
}

export class ParticipantTableRow extends React.Component {
  static propTypes = {
    pid: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    i: PropTypes.number.isRequired
  }
  render() {
    const {pid, data, i} = this.props;
    const participant = data.entities.participants[pid];
    const urlTeam = `/team/${pid}`;
    const points = participant.validated_challenges.map(cid => data.entities.challenges[cid].points).reduce((prev, cur) => prev + cur, 0);
    const tags = participant.validated_challenges.map(cid => data.entities.challenges[cid].tags).reduce((prev, cur) => prev.concat(cur), []);
    const tagCount = {};
    tags.forEach(t => {
      tagCount[t] = tagCount[t] ? tagCount[t] + 1 : 1;
    });

    return (
      <tr>
        <td>{i + 1}</td>
        <td>
          <NavLink to={urlTeam} className="item" activeClassName="active">{participant.name}</NavLink>
        </td>
        <td>{points}</td>
        {Object.keys(tagCount).length > 0 ?
          <td>
            {Object.keys(tagCount).map((k, i) => {
              const tag = data.entities.tags[k];
              return <Tag key={i} data={tag} nb={tagCount[k]}/>;
            })}
          </td>
            :
          <td>Aucun tag</td>}
      </tr>
    );
  }
}
