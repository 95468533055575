import * as types from "../constants/actionTypes";

function team(state = {}, action) {
  switch (action.type) {
    case types.TEAM_REQUEST:
      return state;
    case types.TEAM_SUCCESS:
      return action.payload;
    case types.TEAM_FAILURE:
      return state;
    default:
      return state;
  }
}

export default team;
