import {normalize, schema} from "normalizr";
import {createAction} from "redux-api-middleware";

import * as endpoints from "../constants/endpoints";
import * as types from "../constants/actionTypes";
import {getCookie} from "./csrftoken"

const participant = new schema.Entity("participants");
const challenge = new schema.Entity("challenges");
const tag = new schema.Entity("tags");

const participation = new schema.Entity("participations");
const proof = new schema.Entity("proofs");

challenge.define({tags: [tag]});
participant.define({validated_challenges: [challenge]});
participation.define({proofs: [proof]});

export const requestParticipants = () => createAction({
  endpoint: endpoints.PARTICIPANTS_URL,
  method: "GET",
  headers: {"Accept": "application/json"},
  types: [
    types.PARTICIPANTS_REQUEST,
    {
      type: types.PARTICIPANTS_SUCCESS,
      payload: (action, state, res) => {
        return res.json().then(json => normalize(json, [participant]));
      }
    },
    types.PARTICIPANTS_FAILURE
  ]
})

export const requestChallenges = () => createAction({
  endpoint: endpoints.CHALLENGES_URL,
  method: "GET",
  headers: {"Accept": "application/json"},
  types: [
    types.CHALLENGES_REQUEST,
    {
      type: types.CHALLENGES_SUCCESS,
      payload: (action, state, res) => {
        return res.json().then(json => normalize(json, [challenge]));
      }
    },
    types.CHALLENGES_FAILURE
  ]
})

export const requestParticipations = (token) => createAction({
  endpoint: endpoints.VALIDATION_PARTICIPATION_URL,
  method: "GET",
  headers: {"Accept": "application/json", "Authorization": `Token ${token}`},
  types: [
    types.PARTICIPATIONS_REQUEST,
    {
      type: types.PARTICIPATIONS_SUCCESS,
      payload: (action, state, res) => {
        return res.json().then(json => normalize(json, [participation]));
      }
    },
    types.PARTICIPATIONS_FAILURE
  ]
})

export const checkDuplicateParticipation = (p, c) => createAction({
  endpoint: `${endpoints.VALIDATION_DUPLICATE_URL}?participant=${p}&challenge=${c}`,
  method: "GET",
  types: [
    types.CHECK_DUPLICATE_PARTICIPATION_REQUEST,
    types.CHECK_DUPLICATE_PARTICIPATION_SUCCESS,
    types.CHECK_DUPLICATE_PARTICIPATION_FAILURE
  ]
})

export const submitParticipation = (participation) => createAction({
  endpoint: endpoints.VALIDATION_PARTICIPATION_URL,
  method: "POST",
  headers: {"X-CSRFToken": getCookie("csrftoken")},
  body: participation,
  types: [
    types.SUBMIT_PARTICIPATION_REQUEST,
    types.SUBMIT_PARTICIPATION_SUCCESS,
    types.SUBMIT_PARTICIPATION_FAILURE
  ]
})

export function addMessage(content, title = undefined, blockClass = undefined, iconClass = undefined) {
  return {
    type: types.ADD_MESSAGE,
    title,
    content,
    blockClass,
    iconClass,
    dismissed: false
  };
}

export function dismissLastMessage() {
  return {
    type: types.DISMISS_LAST_MESSAGE
  };
}

export const validateParticipation = (id, token) => createAction({
  endpoint: `${endpoints.VALIDATION_PARTICIPATION_URL}${id}/`,
  method: "PATCH",
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Token ${token}`,
    "X-CSRFToken": getCookie('csrftoken')
  },
  body: JSON.stringify({"valid": true}),
  types: [
    types.VALIDATE_PARTICIPATION_REQUEST,
    types.VALIDATE_PARTICIPATION_SUCCESS,
    types.VALIDATE_PARTICIPATION_FAILURE
  ]
})

export const deleteParticipation = (id, token) => createAction({
  endpoint: `${endpoints.VALIDATION_PARTICIPATION_URL}${id}/`,
  method: "DELETE",
  headers: {
    "Authorization": `Token ${token}`,
    "X-CSRFToken": getCookie('csrftoken')
  },
  types: [
    types.DELETE_PARTICIPATION_REQUEST,
    types.DELETE_PARTICIPATION_SUCCESS,
    types.DELETE_PARTICIPATION_FAILURE
  ]
})

export const requestUserToken = (username, password) => createAction({
  endpoint: endpoints.PERMISSIONS_USER_TOKEN_URL,
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": getCookie('csrftoken')
  },
  method: "POST",
  body: JSON.stringify({username, password}),
  types: [
    types.USER_TOKEN_REQUEST,
    types.USER_TOKEN_SUCCESS,
    types.USER_TOKEN_FAILURE
  ]
})

export function destroyUserToken() {
  return {
    type: types.DESTROY_USER_TOKEN
  };
}

export function clearAdminSpecificData() {
  return {
    type: types.CLEAR_ADMIN_SPECIFIC_DATA
  };
}

export function createModal(header, content, objId) {
  return {
    type: types.CREATE_MODAL,
    header,
    content,
    active: false,
    objId
  };
}

export function deleteModal() {
  return {
    type: types.DELETE_MODAL
  };
}

export function showModal() {
  return {
    type: types.SHOW_MODAL
  };
}

export function hideModal() {
  return {
    type: types.HIDE_MODAL
  };
}
/* eslint quote-props: ["error", "consistent"], camelcase: ["error", {properties: "never"}] */
