import React from "react";
import PropTypes from 'prop-types';

export default class Message extends React.Component {
  static propTypes = {
    msg: PropTypes.object.isRequired
  }

  render() {
    const {msg} = this.props;
    const iconClass = msg.iconClass ? `ui ${msg.blockClass} icon message` : `ui ${msg.blockClass} message`;
    const blockClass = msg.blockClass ? iconClass : "ui message";
    return (
      <div className={blockClass}>
        {msg.iconClass && <i className={msg.iconClass}/>}
        <div className="content">
          {msg.title && <div className="header">{msg.title}</div>}
          <p>{msg.content}</p>
        </div>
      </div>
    );
  }
}
