import {useHistory} from "react-router-dom";

import * as actions from "../actions/actions";
import store from "../store/store";
import React from "react";

const LogoutButton = () => {
  let history = useHistory();

  function handleLogout() {
    store.dispatch(actions.destroyUserToken());
    store.dispatch(actions.clearAdminSpecificData());
    history.push("/login");
  }

  return <button className="item" onClick={handleLogout}><i className="sign out icon"/>Déconnexion</button>
};

export default LogoutButton;
