import * as types from "../constants/actionTypes";

function participations(state = {}, action) {
  switch (action.type) {
    case types.PARTICIPATIONS_REQUEST:
      return state;
    case types.PARTICIPATIONS_SUCCESS:
      return action.payload;
    case types.PARTICIPATIONS_FAILURE:
      return state;
    case types.CLEAR_ADMIN_SPECIFIC_DATA:
      return {};
    default:
      return state;
  }
}

export default participations;
