import React from "react";
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

import LogoutButton from "./logout";

export default class Navbar extends React.Component {
  static propTypes = {
    user: PropTypes.object
  };

  render() {
    const {user} = this.props;

    return (
      <div className="ui top attached stackable menu">
        <NavLink to="/scoreboard" className="item" activeClassName="active"><i className="trophy icon"/>Scoreboard</NavLink>
        <NavLink to="/participate" className="item" activeClassName="active"><i className="plus icon"/>Participer</NavLink>
        <NavLink to="/validation" className="item" activeClassName="active"><i className="settings icon"/>Validation</NavLink>
        <a className="item" href="mailto:defis.inte@bde.enseeiht.fr"><i className="mail icon"/>Contact</a>
        <div className="right menu">
          <a className="item" href="/admin"><i className="wrench icon"/>Gestion</a>
          {(!user || !user.hasOwnProperty('token')) &&
            <NavLink to="/login" className="item" activeClassName="active"><i className="sign in icon"/>Connexion</NavLink>}
          {user && user.hasOwnProperty('token') &&
            <LogoutButton/>}
        </div>
      </div>
    );
  }
}
