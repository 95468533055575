import * as types from "../constants/actionTypes";

function validate(state = [], action) {
  switch (action.type) {
    case types.VALIDATE_PARTICIPATION_REQUEST:
      return state;
    case types.VALIDATE_PARTICIPATION_SUCCESS:
      return action.payload;
    case types.VALIDATE_PARTICIPATION_FAILURE:
      return state;
    default:
      return state;
  }
}

export default validate;
