import React from "react";
import PropTypes from 'prop-types';

import Challenges from "./challenges";
import Participants from "./participants";

export default class Scoreboard extends React.Component {
  static propTypes = {
    pdata: PropTypes.object.isRequired,
    cdata: PropTypes.object.isRequired
  };
  render() {
    return (
      <div>
        <Participants pdata={this.props.pdata}/>
        <div className="vspace"/>
        <Challenges cdata={this.props.cdata}/>
      </div>
    );
  }
}
