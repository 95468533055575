import React from "react";
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import {Challenge} from "./challenges";

class Team extends React.Component {
  static propTypes = {
    cdata: PropTypes.object,
    pdata: PropTypes.object,
    params: PropTypes.object
  }

  render() {
    const {cdata, pdata} = this.props;
    const teamNum = this.props.match.params.teamName;
    const vChall = pdata.hasOwnProperty("entities") && pdata.entities.participants[teamNum].validated_challenges;
    const unvChall = cdata.hasOwnProperty("result") && cdata.result.filter(el => !vChall.includes(el));
    return (
      <div>
        {pdata.hasOwnProperty("entities") && <h1>Team : {pdata.entities.participants[teamNum].name}</h1>}
        <h2>Défis validé</h2>
        {vChall &&
        (vChall.length > 0 ?
            <div className="ui divided items">
              {vChall.map((cid, i) => <Challenge key={i} cid={cid} data={cdata}/>)}
            </div> :
            vChall.length <= 0 &&
            <p>Aucun défi validé</p>)}
        <h2>Défis non validé</h2>
        {unvChall &&
        (unvChall.length > 0 ?
            <div className="ui divided items">
              {unvChall.map((cid, i) => <Challenge key={i} cid={cid} data={cdata}/>)}
            </div> :
            unvChall.length <= 0 &&
            <p>Tu as validé tous les défis ! Félicitation</p>)}

      </div>
    );
  }
}

export default withRouter(Team);
