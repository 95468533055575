import React from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter as Router,
} from "react-router-dom";
import {Provider} from "react-redux";
import Main from "./components/main";
import store from "./store/store";
import * as actions from "./actions/actions";

import 'semantic-ui-css/semantic.min.css';
import "./index.css";

const dismissMessage = () => {
  store.dispatch(actions.dismissLastMessage());
};


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Main onChange={{dismissMessage}}/>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
