import React from "react";
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form'

import {FieldError} from "./errors";
import Message from "./message";

import * as actions from "../actions/actions";
import store from "../store/store";
import CSRFToken from "../actions/csrftoken";

const MAX_FILES_NUMBER = 15;
const MAX_SIZE = 100;
const ACCEPTED_MIME_TYPES = ["image", "video"];

class ParticipantDropdown extends React.Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
  }

  render() {
    const {input: {onChange, onBlur, name}, meta: {data, touched, error}} = this.props;
    return (
      <div className="field">
        {touched && error && <FieldError text={error}/>}
        <select name={name} className="ui dropdown" onChange={onChange} onBlur={onBlur}>
          <option value="">Sélectionnez une équipe</option>
          {data.hasOwnProperty("result") && data.result.map((k, i) => {
            const participant = data.entities.participants[k];
            return <option key={i} value={participant.id}>{participant.name}</option>;
          })}
        </select>
      </div>
    );
  }
}

class ChallengeDropdown extends React.Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
  }

  render() {
    const {input: {onChange, onBlur, name}, meta: {data, touched, error}} = this.props;
    return (
      <div className="field">
        {touched && error && <FieldError text={error}/>}
        <select name={name} className="ui dropdown" onChange={onChange} onBlur={onBlur}>
          <option value="">Sélectionnez un défi</option>
          {data.hasOwnProperty("result") && data.result.map((k, i) => {
            const challenge = data.entities.challenges[k];
            return <option key={i} value={challenge.id}>{challenge.name}</option>;
          })}
        </select>
      </div>
    );
  }
}

class UploadButton extends React.Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
  }

  render() {
    const {input: {onBlur, onChange, value}, meta} = this.props;
    return (
      <div className="field">
        {meta.touched && meta.error && <FieldError text={meta.error}/>}
        <label htmlFor="proofs" className="ui labeled icon button">
          <i className="upload icon"/>
          {(!value || (value && value.length <= 0)) && <span>Sélectionner des photos/vidéos</span>}
          {value && value.length > 0 && <span>{value.length} photo(s)/vidéo(s)</span>}
        </label><br/>
        <input type="file" name="proofs" id="proofs" className="file-input" onBlur={onBlur} onChange={({ target }) => onChange(target.files)} multiple/>
      </div>
    );
  }
}

export default class ParticipationForm extends React.Component {
  static propTypes = {
    cdata: PropTypes.object.isRequired,
    pdata: PropTypes.object.isRequired,
    messages: PropTypes.array.isRequired
  }

  render() {
    const {cdata, pdata, messages} = this.props;
    const lastMessage = messages.slice(-1)[0];
    return (
      <div>
        <h2>Participer</h2>
        {messages && messages.length > 0 && !lastMessage.dismissed && <Message msg={lastMessage}/>}
        <Form onSubmit={submit} touchOnChange={true} validate={validate}>
          {({ handleSubmit, submitting, valid }) => (
            <form className="ui form error" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
              <CSRFToken />
              <div>
                <label htmlFor="participant">Équipe</label>
                <Field name="participant" component={ParticipantDropdown} data={pdata}/>
              </div>
              <div className="vspace"/>
              <div>
                <label htmlFor="challenge">Défi</label>
                <Field name="challenge" component={ChallengeDropdown} data={cdata}/>
              </div>
              <div className="vspace"/>
              <fieldset>
                <legend>Photos/vidéos</legend>
                <p><em>
                  Vous pouvez envoyer jusqu'à {MAX_FILES_NUMBER} photos/vidéos (maximum {MAX_SIZE}Mo).<br/>
                  N'envoyez des vidéos que si c'est vraiment nécessaire !<br/>
                  Toute participation contenant inutilement des vidéos sera supprimée sans avertissement.
                </em></p>
                <Field name="proofs" component={UploadButton}/>
              </fieldset>
              <div className="vspace"/>
              <button type="submit" disabled={!valid || submitting} className="ui submit labeled icon positive button">
                <i className="icon send"/>
                Envoyer
              </button>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

const submit = (data, form, callback) => {
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("participant", data.participant);
    formData.append("challenge", data.challenge);
    for (let i = 0; i < data.proofs.length; i++) {
      formData.append("proofs", data.proofs[i]);
    }

    store.dispatch(actions.addMessage("Veuillez patienter...", "Envoi en cours", "info", "notched circle loading icon"));
    store.dispatch(actions.submitParticipation(formData)).then(response => {
      if (response.error) {
        if (response.payload.status === 400) {
          // 400 Bad Request : Données invalides reçues
          store.dispatch(actions.addMessage("Erreur lors de la vérification de la participation", "Erreur", "error", "warning circle icon"));
        } else if (response.payload.status === 409) {
          // 409 Conflict : Déjà participé
          store.dispatch(actions.addMessage("Cette équipe a déjà participé à ce défi", "Erreur", "error", "warning circle icon"));
        } else if (response.payload.status === 415) {
          // 415 Unsupported Media Type : fichiers de format incorrect envoyés
          store.dispatch(actions.addMessage("Les fichiers doivent être des photos ou des vidéos", "Erreur", "error", "warning circle icon"));
        } else {
          store.dispatch(actions.addMessage("Une erreur inattendue est survenue lors de l'envoi.", "Erreur", "error", "warning circle icon"));
        }
        reject();
      } else {
        store.dispatch(actions.addMessage(null, "Participation envoyée", "success", "checkmark icon"));
        sleep(5000).then(() => store.dispatch(actions.dismissLastMessage()));
        form.restart();
        resolve();
      }
    })
  });
};

const validate = (values) => {
  const errors = {};

  if (!values.participant) {
    errors.participant = "Veuillez sélectionner une équipe";
  }

  if (!values.challenge) {
    errors.challenge = "Veuillez sélectionner un défi";
  }

  if (!values.proofs || (values.proofs && values.proofs.length <= 0)) {
    errors.proofs = "Veuillez choisir des photos/vidéos";
  } else if (values.proofs.length > MAX_FILES_NUMBER) {
    delete values.proofs;
    errors.proofs = `Le nombre de photos/vidéos est limité à ${MAX_FILES_NUMBER}`;
  } else {
    const validTypes = Object.keys(values.proofs).map(k => {
      let match = false;
      ACCEPTED_MIME_TYPES.forEach(t => {
        if (values.proofs[k].type.match(`${t}.*`)) {
          match = true;
        }
      });
      return match;
    }).reduce((prev, cur) => prev && cur, true);

    if (!validTypes) {
      delete values.proofs;
      errors.proofs = "Les fichiers doivent être des photos ou des vidéos";
    }
  }

  return errors;
};
