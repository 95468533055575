export const TEAM_REQUEST = "TEAM_REQUEST";
export const TEAM_SUCCESS = "TEAM_SUCCESS";
export const TEAM_FAILURE = "TEAM_FAILURE";

export const PARTICIPANTS_REQUEST = "PARTICIPANTS_REQUEST";
export const PARTICIPANTS_SUCCESS = "PARTICIPANTS_SUCCESS";
export const PARTICIPANTS_FAILURE = "PARTICIPANTS_FAILURE";

export const CHALLENGES_REQUEST = "CHALLENGES_REQUEST";
export const CHALLENGES_SUCCESS = "CHALLENGES_SUCCESS";
export const CHALLENGES_FAILURE = "CHALLENGES_FAILURE";

export const PARTICIPATIONS_REQUEST = "PARTICIPATIONS_REQUEST";
export const PARTICIPATIONS_SUCCESS = "PARTICIPATIONS_SUCCESS";
export const PARTICIPATIONS_FAILURE = "PARTICIPATIONS_FAILURE";

export const SUBMIT_PARTICIPATION_REQUEST = "SUBMIT_PARTICIPATION_REQUEST";
export const SUBMIT_PARTICIPATION_SUCCESS = "SUBMIT_PARTICIPATION_SUCCESS";
export const SUBMIT_PARTICIPATION_FAILURE = "SUBMIT_PARTICIPATION_FAILURE";

export const CHECK_DUPLICATE_PARTICIPATION_REQUEST = "CHECK_DUPLICATE_PARTICIPATION_REQUEST";
export const CHECK_DUPLICATE_PARTICIPATION_SUCCESS = "CHECK_DUPLICATE_PARTICIPATION_SUCCESS";
export const CHECK_DUPLICATE_PARTICIPATION_FAILURE = "CHECK_DUPLICATE_PARTICIPATION_FAILURE";

export const VALIDATE_PARTICIPATION_REQUEST = "VALIDATE_PARTICIPATION_REQUEST";
export const VALIDATE_PARTICIPATION_SUCCESS = "VALIDATE_PARTICIPATION_SUCCESS";
export const VALIDATE_PARTICIPATION_FAILURE = "VALIDATE_PARTICIPATION_FAILURE";

export const DELETE_PARTICIPATION_REQUEST = "DELETE_PARTICIPATION_REQUEST";
export const DELETE_PARTICIPATION_SUCCESS = "DELETE_PARTICIPATION_SUCCESS";
export const DELETE_PARTICIPATION_FAILURE = "DELETE_PARTICIPATION_FAILURE";

export const USER_TOKEN_REQUEST = "USER_TOKEN_REQUEST";
export const USER_TOKEN_SUCCESS = "USER_TOKEN_SUCCESS";
export const USER_TOKEN_FAILURE = "USER_TOKEN_FAILURE";

export const DESTROY_USER_TOKEN = "DESTROY_USER_TOKEN";

export const CLEAR_ADMIN_SPECIFIC_DATA = "CLEAR_ADMIN_SPECIFIC_DATA";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const DISMISS_LAST_MESSAGE = "DISMISS_LAST_MESSAGE";

export const CREATE_MODAL = "CREATE_MODAL";
export const DELETE_MODAL = "DELETE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
