import React from "react";
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react'

import Message from "./message";

import * as actions from "../actions/actions";
import store from "../store/store";

const Confirm = (props) => {
  const {active, header, content, cancelButton, confirmButton, onCancel, onConfirm} = props;

  return (
    <Modal open={active}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>{content}</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content={cancelButton} color='black' onClick={onCancel}/>
        <Button content={confirmButton} labelPosition='right' icon='checkmark' onClick={onConfirm} positive/>
      </Modal.Actions>
    </Modal>
  )
}

class Participation extends React.Component {
  static propTypes = {
    pid: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    pdata: PropTypes.object.isRequired,
    cdata: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleValidate = this.handleValidate.bind(this)
  }

  handleDelete() {
    const {pid} = this.props;

    store.dispatch(actions.createModal("Supprimer ?",
      "Êtes-vous sûr de vouloir supprimer cette participation ?",
      pid));
    store.dispatch(actions.showModal());
  }

  handleValidate() {
    const {pid, user} = this.props;
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    store.dispatch(actions.validateParticipation(pid, user.token)).then(response => {
      if (response.error) {
        store.dispatch(actions.addMessage("Impossible de valider la participation. A-t-elle déjà été validée ?",
          "Erreur", "error", "warning circle icon"));
      } else {
        store.dispatch(actions.addMessage(null, "Participation validée", "success", "checkmark icon"));
        sleep(3000).then(() => store.dispatch(actions.dismissLastMessage()));
        store.dispatch(actions.requestParticipations(user.token));
        store.dispatch(actions.requestParticipants());
        store.dispatch(actions.requestChallenges());
      }
    });
  }

  render() {
    const {pid, data, pdata, cdata} = this.props;
    const participation = data.entities.participations[pid];
    const participant = pdata.entities.participants[participation.participant];
    const points = participant.validated_challenges.map(cid => cdata.entities.challenges[cid].points).reduce((prev, cur) => prev + cur, 0);
    const challenge = cdata.entities.challenges[participation.challenge];

    return (
      <div className="card">
        <div className="content">
          <div className="header">{participant.name} ({points} points)</div>
          <div className="meta">Défi : {challenge.name} ({challenge.points} points)</div>
          <h4 className="ui sub header">Photos/vidéos</h4>
          <div className="ui small feed">
            {participation.proofs.map((pr, i) => {
              const proof = data.entities.proofs[pr];
              return (
                <div className="event" key={i}>
                  <div className="content">
                    <div className="summary">
                      <a href={"/media/" + proof.file.split("/").slice(-1)[0]} rel="noreferrer" target="_blank">Ouvrir n°{i + 1}</a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="extra content">
          <div className="ui two buttons">
            <button type="button" className="ui basic positive button" onClick={this.handleValidate}>
              <i className="checkmark icon"/>
            </button>
            <button type="button" className="ui basic negative button" onClick={this.handleDelete}>
              <i className="trash alternate outline icon"/>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

class Validation extends React.Component {
  static propTypes = {
    modal: PropTypes.object,
    participations: PropTypes.object,
    messages: PropTypes.array,
    pdata: PropTypes.object,
    cdata: PropTypes.object,
    user: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  componentDidMount() {
    const {user} = this.props;

    if (user.hasOwnProperty('token')) {
      store.dispatch(actions.requestParticipations(user.token)).then(response => {
        if (response.error) {
          if (response.payload.status === 403) {
            this.props.history.push("/forbidden")
          } else {
            store.dispatch(actions.addMessage("Une erreur inattendue est survenue lors de la récupération des participations.",
              "Erreur", "error", "warning circle icon"));
          }
        }
      });
    } else {
      this.props.history.push("/login")
    }
  }

  handleConfirm() {
    const {modal, user} = this.props;

    store.dispatch(actions.hideModal());
    store.dispatch(actions.deleteModal());
    store.dispatch(actions.deleteParticipation(modal.objId, user.token)).then(response => {
      if (response.error) {
        store.dispatch(actions.addMessage("Impossible de supprimer la participation.",
          "Erreur", "error", "warning circle icon"));
      } else {
        store.dispatch(actions.requestParticipations(user.token));
        store.dispatch(actions.requestParticipants());
        store.dispatch(actions.requestChallenges());
      }
    });
  }

  handleCancel() {
    store.dispatch(actions.hideModal());
    store.dispatch(actions.deleteModal());
  }

  render() {
    const {participations, messages, pdata, cdata, modal} = this.props;
    const lastMessage = messages.slice(-1)[0];

    return (
      <div>
        <h2>Validation</h2>
        {messages && messages.length > 0 && !lastMessage.dismissed && <Message msg={lastMessage}/>}
        {participations.hasOwnProperty("result") &&
          pdata.hasOwnProperty("result") &&
          cdata.hasOwnProperty("result") &&
          (participations.result.length > 0 ?
            <div className="ui three stackable cards">
              {participations.result.map((pid, i) =>
                <Participation key={i} pid={pid} data={participations} {...this.props}/>)}
            </div> :
            participations.result.length <= 0 &&
              <p>Aucune participation en attente de validation</p>)}

            {modal.hasOwnProperty("header") &&
              modal.hasOwnProperty("content") &&
              modal.hasOwnProperty("active") &&
              <Confirm
                active={modal.active}
                header={modal.header}
                content={modal.content}
                cancelButton="Annuler"
                confirmButton="Supprimer"
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
                />}
      </div>
    );
  }
}

export default withRouter(Validation)
