import React from "react";
import PropTypes from 'prop-types';

export class FieldError extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string.isRequired
  }

  render() {
    const {title = undefined, text} = this.props;
    return (
      <div className="ui pointing below red label">
        {title && <div className="header">{title}</div>}
        <p><i className="warning circle icon"/> {text}</p>
      </div>
    );
  }
}
