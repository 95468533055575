import React from "react";
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form'
import { withRouter } from "react-router";
import store from "../store/store";

import {FieldError} from "./errors";
import Message from "./message";
import CSRFToken from '../actions/csrftoken';

import * as actions from "../actions/actions";
import * as types from "../constants/actionTypes";

class TextField extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
  }

  render() {
    const {input: {onChange, name, type}, label, placeholder, meta} = this.props;
    return (
      <div className="field">
        {meta.touched && meta.error && <FieldError text={meta.error}/>}
        <label>{label}</label>
        <input type={type} name={name} placeholder={placeholder} onChange={onChange}/>
      </div>
    );
  }
}

class LoginForm extends React.Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    user: PropTypes.object
  }

  componentDidMount() {
    const {user} = this.props;
    if (user && user.hasOwnProperty('token')) {
      this.props.history.push("/validation");
    }
  }

  handleSubmit = (data) => {
    return new Promise((resolve, reject) => {
      store.dispatch(actions.requestUserToken(data.username, data.password)).then(response => {
        if (response.error) {
          if (response.type === types.USER_TOKEN_FAILURE) {
            store.dispatch(actions.addMessage("Vérifiez le nom d'utilisateur et le mot de passe.", "Échec de connexion", "error", "warning circle icon"));
          } else {
            store.dispatch(actions.addMessage("Une erreur inattendue est survenue lors de la connexion.", "Erreur", "error", "warning circle icon"));
          }
          reject();
        } else {
          this.props.history.push("/validation");
          resolve();
        }
      });
    });
  }

  render() {
    const {messages} = this.props;
    const lastMessage = messages.slice(-1)[0];
    return (
      <div>
        <h2>Connexion</h2>
        {messages && messages.length > 0 && !lastMessage.dismissed && <Message msg={lastMessage}/>}
        <Form
          onSubmit={this.handleSubmit}
          touchOnChange={true}
          validate={validate}
        >
          {({ handleSubmit }) => (
            <form className="ui form" method="post" onSubmit={handleSubmit}>
              <CSRFToken />
              <Field name="username" type="text" component={TextField} label="Nom d'utilisateur" placeholder="Nom d'utilisateur"/>
              <Field name="password" type="password" component={TextField} label="Mot de passe" placeholder="Mot de passe"/>
              <button className="ui submit labeled icon positive button" type="submit">
                <i className="sign in icon"/>
                Se connecter
              </button>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};

  if (!values.username) {
    errors.username = "Ce champ est obligatoire";
  }

  if (!values.password) {
    errors.password = "Ce champ est obligatoire";
  }

  return errors;
};

export default withRouter(LoginForm);
