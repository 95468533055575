import * as types from "../constants/actionTypes";

function messages(state = [], action) {
  let newState;
  switch (action.type) {
    case types.ADD_MESSAGE:
      // Masquer le dernier message le cas échéant
      if (state.length > 0) {
        newState = [
          ...state.slice(0, state.length - 1),
          Object.assign({}, state[state.length - 1], {dismissed: true}),
          {
            title: action.title,
            content: action.content,
            date: Date.now(),
            blockClass: action.blockClass,
            iconClass: action.iconClass,
            dismissed: action.dismissed
          }
        ];
      } else {
        newState = [
          ...state,
          {
            title: action.title,
            content: action.content,
            date: Date.now(),
            blockClass: action.blockClass,
            iconClass: action.iconClass,
            dismissed: action.dismissed
          }
        ];
      }
      return newState;
    case types.DISMISS_LAST_MESSAGE:
      if (state.length > 0) {
        newState = [
          ...state.slice(0, state.length - 1),
          Object.assign({}, state[state.length - 1], {dismissed: true})
        ];
      } else {
        newState = state;
      }
      return newState;
    default:
      return state;
  }
}

export default messages;
