import React from "react";
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import * as actions from "../actions/actions";

import Navbar from "./navbar";
import {Route, Switch, Redirect} from "react-router-dom";
import Scoreboard from "./scoreboard";
import Team from "./team";
import Validation from "./validation";
import Login from "./login";
import Participation from "./participation"

class Main extends React.Component {
  static propTypes = {
    requestParticipants: PropTypes.func.isRequired,
    requestChallenges: PropTypes.func.isRequired,
    user: PropTypes.object
  };
  componentDidMount() {
    this.props.requestParticipants();
    this.props.requestChallenges();
  };
  render() {
    return (
      <div>
        <div className="vspace"/>
        <div className="ui container">
          <Navbar user={this.props.user}/>
          <div className="ui bottom attached segment">
            <Switch>
              <Route path="/scoreboard">
                <Scoreboard pdata={this.props.pdata} cdata={this.props.cdata}/>
              </Route>
              <Route path="/team/:teamName">
                <Team cdata={this.props.cdata} pdata={this.props.pdata}/>
              </Route>
              <Route path="/validation">
                <Validation cdata={this.props.cdata} pdata={this.props.pdata} user={this.props.user} modal={this.props.modal} participations={this.props.participations} messages={this.props.messages}/>
              </Route>
              <Route path="/login">
                <Login user={this.props.user} messages={this.props.messages}/>
              </Route>
              <Route path="/participate">
                <Participation cdata={this.props.cdata} pdata={this.props.pdata} messages={this.props.messages}/>
              </Route>
              {/*<Route path="forbidden" component={Forbidden}/>*/}
              <Redirect to="/scoreboard"/>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cdata: state.challenges,
    pdata: state.participants,
    teamName: state.team,
    participation: state.participation,
    participations: state.participations,
    messages: state.messages,
    modal: state.modal,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
