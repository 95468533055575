import React from "react";
import PropTypes from 'prop-types';

import Tag from "./tags";

export default class Challenges extends React.Component {
  static propTypes = {
    cdata: PropTypes.object.isRequired
  }

  render() {
    const cdata = this.props.cdata;
    return (
      <div>
        <h2>Défis</h2>
        {cdata.hasOwnProperty("result") &&
          (cdata.result.length > 0 ?
            <div className="ui divided items">
              {cdata.result.map((cid, i) => <Challenge key={i} cid={cid} data={cdata}/>)}
            </div> :
            cdata.result.length <= 0 &&
              <p>Aucun défi</p>)}
      </div>
    );
  }
}

export class Challenge extends React.Component {
  static propTypes = {
    cid: PropTypes.number,
    data: PropTypes.object.isRequired
  }

  render() {
    const {cid, data} = this.props;
    const challenge = data.entities.challenges[cid];
    return (
      <div className="item">
        <div className="content">
          <div className="right floated">
            <i className="tags icon"/>
            {challenge.tags.map((tid, i) => {
              const tag = data.entities.tags[tid];
              return <Tag key={i} data={tag}/>;
            })}
          </div>
          <div className="header">{challenge.name}</div>
          <div className="description">{challenge.desc}</div>
          <div className="extra">
            <span><i className="user icon"/>{challenge.author}</span>
            <span><i className="flag icon"/>{challenge.points} points</span>
            {challenge.validations_number > 0 &&
              <span><i className="check circle icon"/>Validé {challenge.validations_number} fois</span>}
            {challenge.validations_number <= 0 &&
              <span><i className="remove circle icon"/>Jamais validé</span>}
          </div>
        </div>
      </div>
    );
  }
}
