import * as types from '../constants/actionTypes';

function user(state = {}, action) {
  switch (action.type) {
    case types.USER_TOKEN_REQUEST:
      return state;
    case types.USER_TOKEN_SUCCESS:
      return action.payload;
    case types.USER_TOKEN_FAILURE:
      return state;
    case types.DESTROY_USER_TOKEN:
      return {};
    default:
      return state;
  }
}

export default user;
