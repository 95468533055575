import {combineReducers} from "redux";
// import {reducer as formReducer} from "redux-form";
import {routerReducer} from "react-router-redux";

import challenges from "./challenges";
import modal from "./modal";
import messages from "./messages";
import participants from "./participants";
import participation from "./participation";
import participations from "./participations";
import user from "./user";
import validate from "./validation";
import team from "./team";

const rootReducer = combineReducers({
  participants,
  challenges,
  modal,
  participation,
  participations,
  messages,
  user,
  validate,
  team,
  // form: formReducer,
  routing: routerReducer
});

export default rootReducer;
