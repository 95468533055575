import * as types from "../constants/actionTypes";

function participation(state = {}, action) {
  switch (action.type) {
    case types.SUBMIT_PARTICIPATION_REQUEST:
      return state;
    case types.SUBMIT_PARTICIPATION_SUCCESS:
      return action.payload;
    case types.SUBMIT_PARTICIPATION_FAILURE:
      return action.payload;
    default:
      return state;
  }
}

export default participation;
